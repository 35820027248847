<template>
<div class="w-full text-center">
    <div class="w-2/3 mt-12 mx-auto">
        <label>Email</label>
        <input type="email" v-model="email" class="w-full p-[5px] bg-gray-50 border-solid border-black border-2">
    </div>
    <div class="w-2/3 mt-12 mx-auto">
        <label>Contraseña</label>
        <input type="password" v-model="password" class="w-full p-[5px] bg-gray-50 border-solid border-black border-2">
    </div>
    <button @click="onLogin" class="bg-gray-500 text-white p-[5px] w-2/3 mt-12">Iniciar Sesión</button>
</div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const email = ref("");
const password = ref("");

async function onLogin(){
    if(email.value != "" && password.value != "")
    {
        store.dispatch("loginUser", {email: email.value, password: password.value})
    }
}
</script>