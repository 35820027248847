import { createRouter, createWebHistory } from 'vue-router'
import store from "../store"
import Landing from '../views/Landing.vue'
import Qrs from '../views/Qrs.vue'
import Productos from '../views/Productos.vue'
import Imagenes from '../views/Imagenes.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    path: "/",
    name: 'Home',
    component: Landing,
    meta: {auth: true}
  },
  {
    path: "/qr",
    name: 'Qrs',
    component: Qrs,
    meta: {auth: true}
  },
  {
    path: "/productos",
    name: 'Productos',
    component: Productos,
    meta: {auth: true}
  },
  {
    path: "/imagenes",
    name: 'Imagenes',
    component: Imagenes,
    meta: {auth: true}
  },
  {
    path: "/login",
    name: 'Login',
    component: Login,
    meta: {auth: false}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.auth && !store.getters.logged)
    next("/login");
  else if(!to.meta.auth && store.getters.logged)
    next("/");
  else
    next();
})

export default router
