<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 86.333 104" enable-background="new 0 0 86.333 104" xml:space="preserve">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.115,59.241c0.458-1.607,1.596-2.721,2.735-3.856
	c9.709-9.689,19.416-19.382,29.095-29.102c1.527-1.533,3.272-2.271,5.436-2.258c6.646,0.039,13.291,0.015,19.937,0.015
	c0.3,0,0.6,0,0.919,0c0.313-0.722,0.561-1.456,0.93-2.124c1.582-2.863,3.995-4.549,7.252-4.996c0.121-0.018,0.236-0.072,0.354-0.11
	c0.651,0,1.305,0,1.956,0c0.088,0.042,0.172,0.104,0.264,0.122c4.203,0.782,6.825,3.266,7.928,7.379
	c0.084,0.307,0.157,0.616,0.235,0.926c0,0.852,0,1.704,0,2.557c-0.257,0.988-0.438,2.002-0.785,2.957
	c-1.062,2.904-3.079,5.006-5.743,6.501c-0.518,0.29-0.721,0.604-0.718,1.216c0.025,6.043-0.01,12.084,0.028,18.127
	c0.014,2.131-0.696,3.868-2.209,5.377c-10.07,10.034-20.111,20.096-30.164,30.146c-1.866,1.866-4.084,2.249-6.13,1.033
	c-0.446-0.266-0.853-0.621-1.222-0.989c-9.472-9.462-18.935-18.933-28.409-28.39c-0.823-0.821-1.353-1.783-1.688-2.875
	C3.115,60.344,3.115,59.793,3.115,59.241z M64.424,37.643c2.01-0.135,3.876-0.563,5.702-1.216c0.438-0.157,0.553-0.378,0.549-0.814
	c-0.021-2.081-0.009-4.161-0.011-6.242c0-2.149-0.917-3.07-3.063-3.071c-9.127,0-18.252,0.011-27.378-0.012
	c-1.421-0.004-2.552,0.471-3.554,1.475C26.611,37.843,16.535,47.906,6.465,57.977c-1.477,1.479-1.469,2.781,0.023,4.273
	c9.413,9.414,18.827,18.828,28.241,28.242c1.482,1.481,2.785,1.48,4.273-0.008c10.07-10.07,20.134-20.145,30.214-30.203
	c1.006-1.004,1.468-2.141,1.461-3.56c-0.029-5.741-0.014-11.483-0.014-17.225c0-0.247,0-0.493,0-0.747
	c-0.131,0-0.207-0.012-0.278,0.002c-1.884,0.387-3.771,0.756-5.646,1.186c-0.261,0.061-0.549,0.371-0.665,0.637
	c-1.022,2.325-2.761,3.646-5.312,3.785c-2.481,0.136-4.77-1.342-5.723-3.62c-0.938-2.24-0.377-4.94,1.367-6.579
	c1.854-1.742,4.503-2.146,6.753-1.02C62.994,34.06,64.023,35.583,64.424,37.643z M63.682,24.041c1.447,0,2.793-0.008,4.138,0
	c3.079,0.02,5.083,2.012,5.103,5.079c0.012,1.653,0.003,3.308,0.003,4.961c0,0.285,0,0.571,0,0.907
	c2.765-1.508,4.546-4.217,4.907-7.508c0.322-2.924-0.668-5.361-3.082-7.061c-2.255-1.587-4.732-1.803-7.262-0.627
	C65.662,20.643,64.408,22.029,63.682,24.041z M61.779,40.061c-1.156-0.172-2.264-0.324-3.369-0.504
	c-0.547-0.089-0.912-0.406-0.982-0.982c-0.094-0.795,0.511-1.321,1.393-1.213c1.076,0.134,2.152,0.277,3.273,0.422
	c-0.367-1.84-1.934-3.096-3.635-3.032c-1.789,0.066-3.324,1.388-3.573,3.075c-0.269,1.812,0.753,3.53,2.433,4.1
	C59.065,42.518,60.834,41.793,61.779,40.061z"/>
</svg>
</template>