<template>
    <RouterLink class="absolute p-2 bg-gray-500 top-[20px] right-[20px]" to="/">Volver al home</RouterLink>
    <IconQrs class="mx-auto w-[100px] sm:w-[150px] mb-12"/>
    <div class="text-center">
        <div v-if="!urlZip && !loading">
            <input @change="saveFile($event)" type="file" id="file" class="custom-file-input block m-auto"/>
            <h2 v-if="error">{{error}}</h2>
            <button v-if="file" @click="generateQrs" class="bg-gray-500 p-2 block m-auto my-5">Generar QRS</button>
        </div>
        <div v-if="loading">
            <IconLoading class="mx-auto mb-12 animate-spin"/>
            <h2>Generando qrs, esto puede tardar varios minutos...</h2>
        </div>
        <div v-if="urlZip && !loading">
            <a :href="urlZip" class="p-2 bg-gray-500">Descargar zip</a>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import readXlsxFile from 'read-excel-file'
import axios from 'axios';
import IconQrs from "@/components/icons/IconQrs.vue"
import IconLoading from "@/components/icons/IconLoading.vue"

let error = ref(false);
let urlZip = ref(null);
let loading = ref(false);
let file = ref(null);

function saveFile($event) {
    const target = $event.target;
    console.log(target.files)

    if (target && target.files && target.files.length > 0) {
        let inputFile = target.files[0];

        if(inputFile.name.split(".")[1] == "xlsx")
        {
            console.log("archivo cargado")
            file.value = inputFile;
            error.value = false;
        }
        else
        {
            file.value = null;
            error.value = "El archivo cargado no es un archivo Excel .xlsx"
        }
    }
    else
    {
        error.value = false;
    }
}

function generateQrs() {
    if(file.value != null)
    {
        readXlsxFile(file.value).then((rows) => {
            if(rows[0].length > 2 || rows[0][0].toUpperCase() != "SKU" || rows[0][1].toUpperCase() != "LINK")
            {
                error.value = 'El archivo debe contener 2 columnas, la primera "SKU" y la segunda "LINK"'
            }
            else if(rows.length > 101)
            {
                error.value = 'El archivo no debe tener mas de 100 productos'
            }
            else
            {
                loading.value = true;
                rows.shift()
                axios.post("/api/generate-qrs", {rows: rows})
                .then(qrRes => {
                    if(!qrRes.data.url) {
                        loading.value = false;
                        error.value = 'Hubo un error inesperado.'
                    }
                    loading.value = false;
                    urlZip.value = "/static/" + qrRes.data.url;
                })
                .catch(err => {
                    console.log(err)
                    loading.value = false;
                    error.value = 'Hubo un error inesperado.'
                })
            }
        })
    }
    else
    {
        loading.value = false;
        error.value = "No se cargo ningun archivo"
    }
}
</script>