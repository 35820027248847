<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 78 104" enable-background="new 0 0 78 104" xml:space="preserve">
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.787,20.204c-1.132,0-2.266,0.007-3.397-0.002
	c-0.867-0.008-1.374-0.453-1.376-1.177c-0.002-0.725,0.504-1.188,1.367-1.192c2.292-0.011,4.583-0.012,6.874,0.001
	c0.854,0.004,1.354,0.487,1.335,1.221c-0.019,0.701-0.504,1.136-1.326,1.146C38.104,20.213,36.945,20.203,35.787,20.204z"/>
<path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M9.395,32.267c0.302,0,0.557,0,0.856,0c0,2.44,0,4.853,0,7.329
	c-0.265,0-0.534,0-0.856,0C9.395,37.173,9.395,34.764,9.395,32.267z"/>
<path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M9.393,46.756c0.304,0,0.555,0,0.855,0c0,2.436,0,4.848,0,7.343
	c-0.26,0.012-0.531,0.023-0.855,0.038C9.393,51.673,9.393,49.262,9.393,46.756z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.295,56.512c-0.364,0-0.601,0.007-0.838-0.001
	c-1.41-0.044-2.466-1.054-2.482-2.453c-0.026-2.404-0.022-4.808-0.001-7.212c0.011-1.317,0.91-2.292,2.22-2.473
	c0.336-0.046,0.679-0.047,1.06-0.072c0-0.748,0-1.475,0-2.272c-0.326,0-0.613,0.022-0.897-0.004
	c-1.422-0.127-2.375-1.137-2.386-2.567c-0.016-2.246-0.006-4.491-0.004-6.736c0.002-1.876,0.933-2.823,2.802-2.86
	c0.132-0.002,0.263-0.013,0.527-0.025c0-0.277,0-0.554,0-0.83c0-3.276-0.006-6.551,0.001-9.827c0.01-4.059,2.824-6.89,6.865-6.897
	c5.31-0.009,10.619,0.002,15.929-0.006c0.699-0.001,1.274,0.162,1.462,0.916c0.205,0.815-0.357,1.453-1.299,1.46
	c-1.928,0.015-3.856,0.005-5.785,0.005c-3.408,0-6.814-0.003-10.223,0.001c-2.841,0.003-4.568,1.744-4.574,4.605
	c-0.002,1.342,0,2.684,0,4.071c15.425,0,30.788,0,46.356,0c-0.098-1.909-0.014-3.848-0.345-5.713
	c-0.325-1.831-2.117-2.958-4.097-2.961c-5.283-0.009-10.566-0.002-15.85-0.005c-0.264,0-0.538,0.027-0.789-0.034
	c-0.613-0.15-0.956-0.565-0.935-1.201c0.02-0.599,0.372-0.981,0.952-1.108c0.253-0.055,0.526-0.03,0.79-0.03
	c5.178-0.001,10.355-0.004,15.532,0c4.245,0.002,7.024,2.795,7.028,7.061c0.005,3.197,0.002,6.393,0.002,9.589
	c0,0.284,0,0.567,0,0.931c1.396,0,2.711-0.001,4.024,0c1.269,0.001,2.536-0.019,3.804,0.01c2.578,0.058,4.677,2.035,4.904,4.594
	c0.023,0.263,0.041,0.527,0.041,0.791c0.002,8.004,0.01,16.008-0.004,24.011c-0.004,2.648-1.416,4.6-3.77,5.211
	c-0.629,0.162-1.305,0.2-1.959,0.203c-4.465,0.016-8.93,0.02-13.393-0.004c-0.59-0.004-0.959,0.164-1.309,0.656
	c-1.235,1.744-2.521,3.454-3.795,5.172c-0.682,0.918-1.42,1.199-2.316,0.908c-0.877-0.284-1.325-0.97-1.337-2.096
	c-0.013-1.242-0.046-2.485,0.012-3.725c0.034-0.753-0.241-0.947-0.954-0.923c-0.731,0.024-1.491-0.012-2.197-0.187
	c-2.229-0.551-3.77-2.494-3.775-4.793c-0.02-8.294-0.026-16.589,0.003-24.883c0.009-2.738,2.219-4.914,4.969-4.934
	c4.543-0.033,9.086-0.012,13.629-0.013c0.313,0,0.626,0,0.991,0c0-1.37,0-2.677,0-4.044c-15.376,0-30.755,0-46.175,0
	c0,18.023,0,36.026,0,54.085c15.374,0,30.734,0,46.23,0c0-0.258,0-0.534,0-0.81c0-3.408-0.002-6.815,0-10.224
	c0-0.237-0.018-0.479,0.015-0.713c0.089-0.629,0.453-1.003,1.095-1.043c0.648-0.04,1.057,0.305,1.213,0.911
	c0.071,0.274,0.051,0.576,0.051,0.866c0.002,5.811,0.004,11.623,0.002,17.434c-0.002,4.356-2.768,7.107-7.142,7.107
	c-12.257,0.002-24.514,0.002-36.771,0c-4.361,0-7.108-2.756-7.109-7.133c-0.002-9.588,0-19.177,0-28.766
	C10.295,57.223,10.295,56.912,10.295,56.512z M48.578,67.928c0.069,0.01,0.139,0.02,0.208,0.031
	c1.034-1.402,2.097-2.784,3.092-4.213c0.718-1.027,1.636-1.455,2.888-1.445c4.541,0.035,9.084,0.016,13.627,0.014
	c2.33-0.002,3.32-0.994,3.32-3.33c0.002-6.417,0.002-12.835,0.002-19.253c0-1.558,0.023-3.117-0.01-4.674
	c-0.037-1.743-1.126-2.814-2.85-2.815c-8.135-0.007-16.269-0.008-24.403,0.001c-1.679,0.002-2.788,1.106-2.79,2.783
	c-0.01,8.16-0.01,16.322,0,24.482c0.002,1.574,0.995,2.635,2.559,2.782c0.419,0.04,0.844,0.022,1.267,0.024
	c2.101,0.008,3.081,0.995,3.091,3.119C48.582,66.265,48.578,67.096,48.578,67.928z M58.93,82.381c-15.453,0-30.827,0-46.257,0
	c0,1.523-0.019,2.996,0.004,4.47c0.038,2.38,1.856,4.196,4.253,4.2c12.593,0.012,25.185,0.012,37.777-0.002
	c2.289-0.002,4.103-1.721,4.21-3.996C58.989,85.526,58.93,83.994,58.93,82.381z M9.395,32.267c0,2.497,0,4.906,0,7.329
	c0.322,0,0.591,0,0.856,0c0-2.476,0-4.889,0-7.329C9.952,32.267,9.697,32.267,9.395,32.267z M9.393,46.756c0,2.506,0,4.917,0,7.381
	c0.324-0.015,0.595-0.026,0.855-0.038c0-2.495,0-4.907,0-7.343C9.948,46.756,9.697,46.756,9.393,46.756z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M39.5,86.699c-0.003,2.064-1.675,3.729-3.727,3.712
	c-2.045-0.017-3.71-1.722-3.679-3.767c0.031-2.028,1.676-3.661,3.696-3.668C37.85,82.972,39.504,84.631,39.5,86.699z M37.119,86.686
	c-0.003-0.729-0.564-1.31-1.285-1.328c-0.744-0.02-1.362,0.591-1.358,1.342c0.004,0.718,0.58,1.312,1.292,1.332
	C36.51,88.051,37.122,87.442,37.119,86.686z"/>
<g>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M59.416,41.11c0-0.818-0.01-1.638,0.004-2.457
		c0.015-0.898,0.419-1.334,1.291-1.343c1.717-0.018,3.434-0.017,5.15,0c0.832,0.008,1.287,0.437,1.301,1.269
		c0.027,1.716,0.025,3.434,0.001,5.15c-0.013,0.862-0.476,1.312-1.348,1.323c-1.69,0.021-3.38,0.023-5.071,0
		c-0.912-0.013-1.313-0.464-1.325-1.406C59.408,42.8,59.416,41.955,59.416,41.11z M61.832,42.635c1.014,0,1.945,0,2.904,0
		c0-1.002,0-1.955,0-2.912c-0.996,0-1.932,0-2.904,0C61.832,40.702,61.832,41.636,61.832,42.635z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M50.104,37.305c0.844,0,1.689-0.014,2.533,0.004
		c0.848,0.018,1.285,0.417,1.299,1.257c0.025,1.742,0.025,3.484-0.003,5.227c-0.014,0.826-0.46,1.248-1.31,1.259
		c-1.689,0.022-3.379,0.021-5.068,0.001c-0.885-0.011-1.327-0.438-1.34-1.315c-0.025-1.715-0.027-3.432,0-5.147
		c0.014-0.883,0.447-1.268,1.354-1.284C48.414,37.293,49.258,37.304,50.104,37.305z M48.625,42.62c1.023,0,1.951,0,2.896,0
		c0-0.998,0-1.931,0-2.896c-0.984,0-1.917,0-2.896,0C48.625,40.701,48.625,41.635,48.625,42.62z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M50.086,49.5c0.845,0,1.689-0.012,2.534,0.004
		c0.866,0.016,1.298,0.394,1.313,1.235c0.028,1.741,0.026,3.484,0.001,5.225c-0.013,0.833-0.441,1.263-1.295,1.276
		c-1.715,0.029-3.432,0.031-5.146-0.002c-0.844-0.016-1.264-0.453-1.277-1.295c-0.025-1.716-0.025-3.432-0.001-5.146
		c0.013-0.897,0.433-1.278,1.339-1.293C48.396,49.489,49.242,49.5,50.086,49.5z M48.633,51.907c0,1.014,0,1.947,0,2.922
		c0.983,0,1.914,0,2.872,0c0-1.001,0-1.951,0-2.922C50.525,51.907,49.615,51.907,48.633,51.907z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M64.797,54.879c0-1.417-0.006-2.683,0.002-3.949
		c0.006-0.918,0.396-1.398,1.126-1.422c0.763-0.026,1.235,0.485,1.243,1.405c0.014,1.638,0.017,3.275-0.001,4.914
		c-0.011,0.959-0.448,1.393-1.418,1.422c-0.767,0.021-1.533,0.024-2.298-0.002c-0.871-0.031-1.359-0.505-1.336-1.238
		c0.021-0.705,0.494-1.106,1.342-1.127C63.872,54.87,64.287,54.879,64.797,54.879z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M58.135,50.327c1.054,0,1.998-0.012,2.941,0.003c0.967,0.016,1.533,0.5,1.5,1.245
		c-0.033,0.722-0.584,1.146-1.516,1.124c-1.344-0.031-2.688-0.076-4.03-0.136c-0.851-0.038-1.248-0.443-1.266-1.286
		c-0.019-0.922-0.019-1.846,0.003-2.768c0.019-0.771,0.461-1.223,1.149-1.231c0.681-0.009,1.144,0.439,1.209,1.194
		c0.024,0.288,0.009,0.579,0.009,0.869C58.136,49.603,58.135,49.864,58.135,50.327z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M61.334,49.031c-1.058-0.003-1.602-0.416-1.594-1.208
		c0.008-0.76,0.535-1.161,1.543-1.167c1.145-0.009,1.703,0.366,1.721,1.155C63.021,48.603,62.43,49.035,61.334,49.031z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M55.76,43.288c0-0.893,0.4-1.389,1.139-1.409
		c0.759-0.021,1.237,0.527,1.236,1.415c-0.002,0.833-0.468,1.406-1.164,1.431C56.24,44.751,55.76,44.182,55.76,43.288z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M58.658,54.88c0.818-0.003,1.383,0.497,1.359,1.202
		c-0.021,0.662-0.599,1.164-1.345,1.168c-0.806,0.004-1.353-0.5-1.337-1.232C57.352,55.301,57.838,54.882,58.658,54.88z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M61.832,42.635c0-0.999,0-1.934,0-2.912c0.973,0,1.908,0,2.904,0
		c0,0.957,0,1.91,0,2.912C63.777,42.635,62.846,42.635,61.832,42.635z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M48.625,42.62c0-0.985,0-1.919,0-2.896c0.979,0,1.911,0,2.896,0
		c0,0.966,0,1.899,0,2.896C50.576,42.62,49.648,42.62,48.625,42.62z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M48.633,51.907c0.982,0,1.893,0,2.872,0c0,0.971,0,1.921,0,2.922
		c-0.958,0-1.889,0-2.872,0C48.633,53.854,48.633,52.921,48.633,51.907z"/>
</g>
</svg>
</template>