
<template>
   <RouterLink class="absolute p-2 bg-gray-500 top-[20px] right-[20px]" to="/">Volver al home</RouterLink>
  <div class="header">
    <h2 class="title">CARGA IMAGENES</h2>
    <span><strong>Tamaño maximo:</strong> 5MB</span>
    <span><strong>Limite de imagenes: </strong> {{ limit }}</span>
  </div>
  <div class="container">
  	<input type="file" multiple @change="onFileChange" /><br><br>
    <div>
        <span v-if="processing == true"> Procesando. La operación puede tardar algunos minutos... </span>
    </div>
    <span v-show="show_message_limit_excedeed == true" class="failure-message"> {{ message_limit_excedeed }}</span>
    <span v-show="show_message_general_error == true" class="failure-message"> {{ message_general_error }}</span>
        <div class="content">
              <div class="thumbnails-container">
                <div class="grid-image-container">
                  <div v-for="(image, key) in images">
                    <div class="grid-item" :id="key">
                      <div class="btn-container">
                      <button type="button" @click="removeImage(key)">&times;</button>
                      </div>
                      <img class="preview img-thumbnail" v-bind:ref="'image'+parseInt( key )" />
                      <div class="description-container">
                        <span class="description-content" >{{ image.name }}</span>
                        <span class="description-content" >{{ getFileSize(image.size) }}</span>
                      </div>
                   </div>
              </div>
          </div>

          <div v-if="images.length > 0" class="btn-submit-container">
                <button v-if="processing == false" class="btn-submit" @click="uploadImages()" type="text" >CARGAR IMAGENES</button>
          </div>

       </div>

       <div class="results-container">
          <div class="success-container">
                <div class="row">
                  <div style="margin-bottom:5px;" v-if="images_response.length > 0">
                    <span class="thumbnail-message">Resultados</span>
                  </div>
                    <div v-for="(image, key) in images_response">
                    <div class="col-md-4" :id="key">
                      <span> {{ image.name }} </span> <span :style="[image.status == 'SUCCESS' ? { 'color' : 'green' } : {  'color' : 'red' }]" > {{ image.message }}</span>
                    </div>
                  </div>
                </div>
              </div>
            <div class="error-container">
                <div class="row">
                  <div style="margin-bottom:5px;" v-if="images_failure.length > 0">
                    <span class="thumbnail-message">Precarga</span>
                  </div>
                    <div v-for="(image, key) in images_failure">
                    <div class="col-md-4" :id="key">
                      <span> {{ image.name }} </span> <span style="color:red;"> {{ image.error }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
     </div>
  </div>
</template>

<script>

  import axios from 'axios'

  export default {
    data() {
      return {
        processing: false,
        images: [],
        images_failure: [],
        images_response: [],
        fileTypes: [
        "image/jpeg",
        "image/png",
        ],
        limit: 100,
        max_file_size: 5000, //10 MB = 10000
        show_message_limit_excedeed: false,
        message_limit_excedeed: 'Ha alcanzado un maximo de 20 imagenes',
        show_message_general_error: false,
        message_general_error: 'Hubo un error en la carga de imagenes',
      }
    },
  components: {},
  methods: {

   uploadImages(){

    console.log('processing started...')
    this.show_message_limit_excedeed = false
    this.processing = true

    let form = new FormData();
    for (let i=0; i<this.images.length; i++){
      form.append('files', this.images[i])
    }
    axios.post("/api/upload-images",form,
        {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }
    ).then(res => {
        this.processing = false
        console.log(res)

        try{
          res.data.forEach( item => {
            console.log(item)
              this.images_response.push({ name : item.data , message : item.message , status : item.status });
              if(item.status == 'SUCCESS'){
              const indexImage =  this.images.findIndex((x) => x.name == item.data)
              console.log('index for '+item.data+' found ? '+indexImage)
                if(indexImage != undefined){
                  console.log('find and remove index: '+indexImage)
                  this.removeImage(indexImage)
                }
              }
          });
        }catch(e){
          console.log('error '+e)
        }

    }).catch(err => {
      this.processing = false
      this.show_message_general_error = true
      console.log(err)
    })

    },

   validFileType(file) {
    return !this.fileTypes.includes(file.type)
    },

   getFileSize(number) {
    if (number < 1024) {
      return `${number} bytes`;
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} KB`
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} MB`
    }
   },

   fileSizeExceded(file){
    //console.log(this.getFileSize(file.size))
    if(file.size > this.max_file_size) return false
    return true
   },

   alreadyExists(imageToCompare){

    for (let i=0; i<this.images.length; i++){
      if(this.images[i].name == imageToCompare.name) return true
    }
     return false;
   },

   validateFormat(file){
    return !file.name.includes('-')
   },

  onFileChange(e) {
      this.show_message_limit_excedeed = false
      this.show_message_general_error = false
      var selectedFiles = e.target.files;

      for (let i=0; i < selectedFiles.length; i++){
        if(this.validateFormat(selectedFiles[i])){
            selectedFiles[i].error = 'Formato incorrecto. Debe tener un separador "-" '
            console.log(selectedFiles[i])
            this.images_failure.push(selectedFiles[i])
        }else if(this.validFileType(selectedFiles[i])){
            selectedFiles[i].error = 'Imagen no compatible con formato'
            this.images_failure.push(selectedFiles[i])
        }else if(this.fileSizeExceded(selectedFiles[i])){
            selectedFiles[i].error = 'Tamaño excedido'
            console.log(selectedFiles[i])
            this.images_failure.push(selectedFiles[i]);
          }else if(this.alreadyExists(selectedFiles[i])){
            selectedFiles[i].error = 'Ya existe en las imagenes seleccionadas'
            console.log(selectedFiles[i])
            this.images_failure.push(selectedFiles[i])
          }else{
            const number_of_images = this.images.length
            if(number_of_images < this.limit){
            this.images.push(selectedFiles[i])
            }else{
              selectedFiles[i].error = 'Limite excedido'
              this.show_message_limit_excedeed = true
              this.images_failure.push(selectedFiles[i])
            }
          }
      }

      this.onSrcBinder()
    },

  removeImage (i) {
    	let arrayImages = this.images;
    	let index = arrayImages.indexOf(arrayImages[i]);
      console.log('index: '+i+' remove: '+i)
		  arrayImages.splice(index, 1);

      this.onSrcBinder()
  },

  onSrcBinder(){
      for (let i=0; i<this.images.length; i++){
          let reader = new FileReader()
          reader.addEventListener('load', function(){
              this.$refs['image' + parseInt( i )][0].src = reader.result
          }.bind(this), false)

      reader.readAsDataURL(this.images[i])
      }
    }

  },
  }
</script>


<style scoped>

body {
	background-color: #e2e2e2;
  min-width: none;
  min-height: none;
}

.title{
  font-size:20px;
  font-weight:bold;
  margin:10px;
}

.header{
  align-items: center;
  margin: auto;
  display: flex;
  flex-direction: column;
}

button {
  border-radius: 5px;
  border: 1px solid #1a1a1a;
  padding: 1px 5px;
  font-size: 1.3em;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  position: absolute;
}
button:hover {
  border-color: #1a1a1a;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.results-container{
  width:30%;
  padding:10px;
}

.thumbnails-container{
  width:70%;
}

#app {
  padding: 2rem;
  width: 100%;
  text-align: center;
}

.container{
  border-radius: 5px;
  padding: 2%;
  min-height: 70vh;
  max-width: 2048px !important;
}

.content{
  display:flex;
}

.preview{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
	width: 100px;
  object-fit: contain;
}
.grid-image-container{
  position: relative;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  background-color: #ffffff8c;
  padding: 20px;
  border-radius: 10px;
}
    .grid-item{
      display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 180px;
    border: 1px solid #8080805e;
    padding: 2px;
    margin: 2px;
    border-radius: 5px;
    background-color: #DCDCDC;
    }
    .btn-container{
      display: flex;
    justify-content: flex-end;
    width: 100%;
    }
    .description-container{
      display: flex;
    flex-direction: column;
    }
    .description-content{
      word-break: break-all;
    }

    .btn-submit-container{
      width: 100%;
    position: relative;
    display: flex;
    margin: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    }

    .btn-submit{
      border-radius: 8px;
    padding: 10px;
    margin-top: 5px;
    font-size: 1em;
    border: 1px solid black;
    border-color: 1px solid black;
    font-weight: 500;
    color: black;
    font-family: inherit;
    cursor: pointer;
    position: relative;
    }

    .btn-submit:hover{
      background-color: #0000003d;
    }

    .thumbnail-message{
      font-weight:bold;
      border-bottom:1px solid black;
      margin-bottom:5px;
    }

    .failure-message{
     color:red;
     font-weight: bold
    }


    @media only screen and (max-width: 960px) {
      .container{
        margin:0;
      }
      #app{
        padding:0;
      }
      .thumbnails-container{
        width:100%;
      }
      .content{
        flex-direction:column-reverse;
      }
      .results-container{
        width:100%;
      }
    }

    @media only screen and (max-width: 600px) {
      .grid-image-container{
        margin: 20px;
        margin-bottom:0;
        grid-template-columns: 1fr;
      }
      .button{
        padding: 8px 8px;
      }
    }
</style>
