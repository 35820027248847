<template>
  <div v-if="loading">Loading...</div>
  <div v-if="!loading && !error">
    <div v-if="$route.name != 'Imagenes'" class="fixed w-[90vw] sm:w-[70vw] bg-white center-fixed p-[20px] sm:p-[50px] border-gray-500 shadow-md border-solid border max-w-[600px]">
      <img v-if="$route.name != 'Qrs'" class="m-auto h-auto w-[400px]" :src="logo"/>
      <RouterView></RouterView>
    </div>
    <div v-if="$route.name == 'Imagenes'">
      <RouterView></RouterView>
    </div>
  </div>
  <div v-if="error">Something went wrong!!!</div>
</template>

<script setup>
import logo from "@/assets/logo.png"
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

const loading = ref(true);
const error = ref(false);

const store = useStore()

const routeName = ref("")

onMounted(async () => {
  try {
    await store.dispatch('initLoad');
    loading.value = false;
  }
  catch (err) {
    loading.value = false;
    error.value = true;
  }
})
</script>