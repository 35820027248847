import { createStore } from 'vuex'
import axios from 'axios';
import router from '../router';

export default createStore({
  state: {
    logged: false,
    user: {
      email: "",
      password: "",
    }
  },
  getters: {
    logged(state){
      return state.logged;
    }
  },
  mutations: {
    CURRENT_USER(state, user) {
      state.user.email = user.email;
      state.user.password = user.password;
      state.logged = true;
    },
  },
  actions: {
    initLoad({ commit }){
      if(localStorage.nobrandtkn)
      {
        axios.defaults.headers.common["authorization"] = localStorage.nobrandtkn;

        axios.get("/api/getUser")
        .then(res => {
          commit("CURRENT_USER", res.data.user);
          router.push("/");
        })
        .catch(err => {
          localStorage.removeItem("nobrandtkn");
          window.location.reload();
        })
      }
    },
    loginUser({ commit }, user){
      axios.post("/api/login", {user: user})
      .then(res => {
        if(res.data.user)
        {
          localStorage.setItem('nobrandtkn', res.data.token);
          axios.defaults.headers.common["authorization"] = res.data.token;
          commit("CURRENT_USER", res.data.user);
          router.push("/");
        }
      })
      .catch(err => {
      });
    }
  },
  modules: {
  }
})
