import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import store from './store';
import './registerServiceWorker';
import "./index.css";

axios.interceptors.response.use(function (res) {
    return res;
}, function (error) {
    if(error.status && error.status.code == 403)
    {
        localStorage.removeItem("nobrandtkn");
        window.location.reload();
    }
    return Promise.reject(error);
});

createApp(App).use(store).use(router).mount('#app')
