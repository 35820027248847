<template>
    <RouterLink class="absolute p-2 bg-gray-500 top-[20px] right-[20px]" to="/">Volver al home</RouterLink>
    <IconQrs class="mx-auto w-[100px] sm:w-[150px] mb-12"/>
    <div class="text-center">
        <div v-if="!urlZip && !loading">
            <input @change="saveFile($event)" type="file" id="file" class="custom-file-input block m-auto"/>
            <h2 v-if="error">{{error}}</h2>
            <button v-if="file" @click="generateTags" class="bg-gray-500 p-2 block m-auto my-5">Generar Tags</button>
        </div>
        <div v-if="loading">
            <IconLoading class="mx-auto mb-12 animate-spin"/>
            <h2>Generando tags, esto puede tardar varios minutos...</h2>
        </div>
    </div>

<!-- Modal Tags -->
<div id="myModal" class="modal" v-show="showModal == true">
  <div class="modal-content">
    <div class="modal-header">
    <button @click="hideModal(this)"  class="close">&times;</button>
    <p>Reporte de carga de Tags</p>
   </div>
    <div class="modal-body">
        <p>{{ messageModal }}</p>
        <p class="c-success" v-if="cantExitosos > 0">Cargados correctamente: {{ cantExitosos }}</p>
        <p class="c-error" v-if="cantError > 0">Cargados con error: {{ cantError }}</p>
        <li v-for="item in response_tags">
            SKU {{ item.sku }}  : <span :class="item.status == 'EXITO' ? 'c-success' : 'c-error'"> {{ item.status }} </span>
            </li>
        </div>
  </div>

</div>



</template>

<style>

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}


.modal-content {
    background-color: #fefefe;
    margin: auto;
    overflow: auto;
    border: 1px solid #888;
    width: 80%;
    height: 100%;
    border: 1px solid black;
}

.modal-header{
   margin: 10px;
   border-bottom: 1px solid black;
   font-weight: bold;
}

.modal-body{
  margin: 15px;
  list-style: none;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 25px;
  font-weight: bold;
  position: relative;
  top: -10px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.c-success{
   color:green;
}

.c-error{
    color:red;
}
</style>




<script setup>
import { ref } from 'vue';
import axios from 'axios';
import IconQrs from "@/components/icons/IconQrs.vue"
import IconLoading from "@/components/icons/IconLoading.vue"


let error = ref(false);
let urlZip = ref(null);
let loading = ref(false);
let file = ref(null);

/* Tag Modal */
let showModal = false;
let messageModal = 'Modal ref';
let cantExitosos = 0;
let cantError = 0;
let response_tags = [];


function saveFile($event) {
    const target = $event.target;
    console.log(target.files)

    if (target && target.files && target.files.length > 0) {
        let inputFile = target.files[0];

        if(inputFile.name.split(".")[1] == "xlsx")
        {
            console.log("archivo cargado")
            file.value = inputFile;
            error.value = false;
        }
        else
        {
            file.value = null;
            error.value = "El archivo cargado no es un archivo Excel .xlsx"
        }
    }
    else
    {
        error.value = false;
    }
}

function generateTags(){
    if(file.value != null){

    console.log('file found!');
    loading.value = true;
    let form = new FormData();
    form.append('file', file.value);
    axios.post("/api/generate-tags",form,
        {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }
    ).then(res => {

        loading.value = false;

        res.data.response.forEach( item => {
          if(item.status == 'EXITO'){
             cantExitosos++;
          }else{
            cantError++;
            response_tags.push(item);
          }
        });

        messageModal = 'Tags generados:';
        showModal = true;
    })
    .catch(err => {
        console.log(err)
        loading.value = false;
        messageModal = err.response.message ? err.response.message : 'Hubo un error al generar los tags';
        console.log(err)
        showModal = true;
    })

    }
}

function hideModal(modal){
    showModal = false;
    document.getElementById('myModal').style.display = 'none';
    cantExitosos = 0;
    cantError = 0;
    file.value = null;
}




</script>